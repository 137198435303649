#footer-main{
    background-color: $dark;
    color: $light;
    min-height: 60px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend .main-padding;

    p{
        margin: 0;
        font-size: 1.2rem;
    }

    #footer_social_icons{

        font-size:1.2rem;

        a{
            color:$light;
            &:hover,&:active,&:focus{
                color:$medium-light;
            }
        }
    
        svg{
            width: 30px;
            height: 30px;
            margin: 5px;
        }
    }
}

@media(max-width:600px){
    #footer-main{

        flex-direction: column;
        justify-content: center;

        p{
            font-size: 1rem;
        }

        #footer_social_icons{
            margin: 10px;
        }
    }
}