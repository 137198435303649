@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,800|Roboto:400,700&display=swap);
h1, h2 {
  font-family: 'Poppins', sans-serif; }

h3 {
  font-family: 'Roboto', sans-serif; }

p, li, a {
  font-family: 'Roboto', sans-serif; }

*:focus {
  outline: none; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif; }

button {
  display: inline-block;
  border-radius: 5px; }

.main-padding, #home #intro, #nav-wrapper, .sections, #content-main section, #skills, #footer-main {
  padding: 15px 10% 15px; }

.highlight {
  color: #69DDFF; }

.shadow, .card, .cards > div {
  box-shadow: 5px 5px 15px -5px #C0BCB5; }

.scroll-adjust {
  padding-top: 80px !important; }

@media (max-width: 600px) {
  .main-padding, #home #intro, #nav-wrapper, .sections, #content-main section, #skills, #footer-main {
    padding: 15px 5% 15px; } }

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  /* Place the container to the left */
  /* Place the container to the right */ }
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px; }
  .timeline .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%; }
    .timeline .container::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      right: -17px;
      background-color: white;
      border: 4px solid #FF9F55;
      top: 15px;
      border-radius: 50%;
      z-index: 1; }
  .timeline .left {
    left: 0; }
  .timeline .right {
    left: 50%; }

#home {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-color: #000F08;
  color: #FBFEF9; }
  #home .sticky-nav {
    border-bottom: solid 2px #69DDFF;
    background-color: #000F08; }
  #home .bg-image {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #000F08 url("/img/home-header.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
    opacity: 0.4; }
  #home .nav-height {
    display: none;
    height: 80px; }
  #home #intro {
    z-index: 1;
    text-align: left; }
    #home #intro .greeting {
      font-size: 1.6rem;
      line-height: 2.4rem; }
    #home #intro h1 {
      font-size: 4rem;
      margin: 15px 0; }
    #home #intro p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      max-width: 80%; }
  #home #footer-intro {
    padding: 30px 0;
    z-index: 1;
    position: relative; }
  #home .icon-scroll,
  #home .icon-scroll:before {
    position: absolute;
    left: 50%; }
  #home .icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    top: 0;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px; }
  #home .icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll; }

@-webkit-keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(46px);
    transform: translateY(46px); } }

@keyframes scroll {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(46px);
    transform: translateY(46px); } }

#nav-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 300; }
  #nav-wrapper .menu-icon {
    display: none; }
  #nav-wrapper #logo img {
    max-width: 50px; }
  #nav-wrapper #logo svg {
    width: 36px;
    height: 36px;
    color: #FBFEF9;
    fill: #FBFEF9; }
  #nav-wrapper nav {
    margin: 0 0 0 auto; }
  #nav-wrapper ul {
    list-style: none;
    margin: 0; }
    #nav-wrapper ul li {
      display: inline-block; }
      #nav-wrapper ul li a {
        color: #FBFEF9;
        text-decoration: none;
        font-size: 1.2rem;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        padding: 0 10px; }
        #nav-wrapper ul li a:hover {
          color: #69DDFF; }
      #nav-wrapper ul li .selected {
        color: #69DDFF; }

@media (max-width: 600px) {
  #intro .greeting {
    font-size: 1.2rem !important;
    line-height: 2.0rem !important; }
  #intro h1 {
    font-size: 2.6rem !important;
    margin: 15px 0; }
  #intro p {
    font-size: 1.0rem !important;
    line-height: 1.6rem !important;
    max-width: 100%; }
  #nav-wrapper {
    position: fixed;
    display: block; }
    #nav-wrapper .menu-icon {
      display: block;
      cursor: pointer; }
      #nav-wrapper .menu-icon .bar1, #nav-wrapper .menu-icon .bar2, #nav-wrapper .menu-icon .bar3 {
        width: 35px;
        height: 5px;
        background-color: #FBFEF9;
        margin: 6px 0;
        -webkit-transition: 0.4s;
        transition: 0.4s; }
    #nav-wrapper .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px); }
    #nav-wrapper .change .bar2 {
      opacity: 0; }
    #nav-wrapper .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px); }
    #nav-wrapper .show-always {
      display: block !important; }
    #nav-wrapper #logo {
      float: left; }
    #nav-wrapper .menu-icon {
      background-color: transparent !important;
      border: none;
      float: right;
      cursor: pointer; }
      #nav-wrapper .menu-icon:focus, #nav-wrapper .menu-icon:hover {
        background-color: transparent !important;
        border: none;
        outline: 0; }
      #nav-wrapper .menu-icon svg {
        height: 30px;
        width: 30px;
        color: #FBFEF9; }
    #nav-wrapper nav {
      clear: both;
      margin: 30px 0 0 0;
      width: 100%; }
    #nav-wrapper ul {
      width: 100%;
      background-color: #2D3142;
      display: none; }
      #nav-wrapper ul li {
        display: block;
        padding: 8px; } }

#back2Top {
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  z-index: 999;
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 10px;
  background-color: #2D3142;
  color: #69DDFF;
  text-align: center;
  font-size: 30px;
  text-decoration: none; }

#back2Top:hover {
  background-color: #69DDFF;
  color: #2D3142; }

.sections, #content-main section {
  width: 100%; }

#content-main {
  background-color: #FBFEF9; }
  #content-main section h2 {
    font-size: 2rem;
    text-align: left;
    color: #2D3142; }
  #content-main section hr {
    margin: 5px auto 15px;
    border-bottom: 4px solid #69DDFF; }

.card, .cards > div {
  text-align: left;
  background-color: white;
  padding: 10px; }
  .card:hover svg, .cards > div:hover svg {
    color: #0E79B2; }
  .card:hover p, .cards > div:hover p {
    border-bottom-color: #0E79B2; }

.cards {
  display: grid;
  grid-template: repeat(2, 250px)/repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 1.4rem;
  clear: both; }
  .cards > div {
    border-radius: 3px;
    text-align: left; }
    .cards > div h3 {
      font-size: 1.4rem;
      margin: 0 0 6px; }
    .cards > div .bordered {
      border-bottom: 5px solid #69DDFF; }
    .cards > div .description {
      font-size: .9rem; }
  .cards .skill_icon {
    width: 35%;
    height: 35%;
    color: #69DDFF;
    display: block;
    margin: 0; }

@media (max-width: 600px) {
  .cards {
    grid-template: repeat(3, 300px)/repeat(auto-fit, minmax(175px, 1fr)); } }

#skills {
  margin-top: 20px;
  text-align: center; }
  #skills #view_more {
    margin: 40px 0;
    float: right; }
    #skills #view_more button {
      border: none !important;
      background-color: none !important;
      color: #69DDFF;
      text-decoration: underline;
      font-size: 1.4rem; }
      #skills #view_more button:focus, #skills #view_more button:focus.active {
        outline: none; }
      #skills #view_more button:hover {
        color: #000F08; }
  #skills #more-skills {
    display: none; }

#resume {
  margin: 30px 0;
  padding: 0px !important;
  background-color: #000F08;
  height: 180px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  #resume .resume-bg-image {
    position: absolute;
    background: url("/img/resume-bg.jpg");
    z-index: 1;
    height: 180px;
    width: 100%;
    opacity: 0.15; }
  #resume .download {
    z-index: 2; }
  #resume button {
    margin: 0px 5px; }
  #resume .pdf {
    background-color: #FBFEF9; }
    #resume .pdf:hover {
      color: #69DDFF;
      background-color: #000F08;
      border-color: #000F08; }
  #resume .word {
    background-color: #69DDFF; }
    #resume .word:hover {
      color: #69DDFF;
      background-color: #000F08;
      border-color: #000F08; }
  #resume h2 {
    font-size: 2.6rem !important;
    margin-bottom: 20px !important;
    color: #FBFEF9 !important;
    display: block !important;
    z-index: 2; }

@media (max-width: 600px) {
  #resume h2 {
    margin-bottom: 10px !important;
    font-size: 1.3rem !important; }
  #resume .download {
    width: 60%; }
  #resume button {
    display: block;
    margin: 10px auto;
    width: 100%; } }

#portfolio .examples {
  margin: 0 auto; }
  #portfolio .examples .example {
    padding: 0px !important;
    text-align: center;
    width: 260px;
    float: left;
    margin: 10px; }

#portfolio .card, #portfolio .cards > div {
  padding: 0px !important;
  margin: 0 auto;
  width: auto; }
  #portfolio .card img, #portfolio .cards > div img {
    max-width: 100%;
    width: 100%;
    height: auto; }
  #portfolio .card .card-header, #portfolio .cards > div .card-header {
    font-weight: 700; }
  #portfolio .card button, #portfolio .cards > div button {
    margin: 5px;
    background-color: #69DDFF;
    border-color: #69DDFF;
    color: #FBFEF9; }
    #portfolio .card button:hover, #portfolio .cards > div button:hover {
      background-color: #000F08;
      border-color: #000F08;
      color: #FBFEF9; }

#portfolio .mobile-controls {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: inline-block; }
  #portfolio .mobile-controls svg {
    color: #FBFEF9;
    position: absolute;
    right: 5px;
    top: 0; }

#portfolio #portfolio-select-controls {
  width: 100%;
  font-size: 1.4rem;
  color: #FBFEF9;
  background-color: #2D3142;
  border-color: #69DDFF;
  margin: 10px auto;
  padding: 5px;
  height: 50px;
  display: block;
  position: relative; }

#portfolio .controls {
  margin: 0 auto 10px;
  text-align: center;
  display: none; }
  #portfolio .controls button {
    margin: 5px 5px;
    background-color: #69DDFF;
    border-color: #69DDFF;
    color: #FBFEF9; }
    #portfolio .controls button:hover {
      background-color: #000F08;
      border-color: #000F08;
      color: #69DDFF; }
  #portfolio .controls .active {
    background-color: #000F08;
    border-color: #000F08;
    color: #69DDFF; }

@media (max-width: 600px) {
  #portfolio #portfolio-select-controls {
    display: block;
    margin: 0 auto;
    width: 100%; }
  #portfolio .controls {
    display: none; }
  #portfolio .examples .example {
    padding: 0px !important;
    text-align: center;
    width: 100%;
    float: none;
    margin: 15px auto; } }

#contact {
  margin: 40px auto;
  clear: both; }

#logos_wrapper {
  display: flex;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  flex-wrap: wrap;
  justify-content: center; }
  #logos_wrapper img {
    margin: 0 auto;
    max-width: 100%;
    width: 100%; }

@media (max-width: 960px) {
  #logos_wrapper div {
    max-width: 120px; } }

@media (max-width: 500px) {
  #logos_wrapper div {
    max-width: 60px; } }

#contact_form {
  margin-top: 40px;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  background-color: #e8e8e8; }
  #contact_form .form-group textarea, #contact_form .form-group input {
    background-color: #2D3142 !important;
    font-size: 1.25rem;
    color: #FBFEF9; }
  #contact_form .form-group textarea:focus, #contact_form .form-group input:focus {
    outline: none !important; }
  #contact_form button {
    background-color: #69DDFF;
    border-color: #69DDFF;
    font-size: 1.25rem; }
    #contact_form button:hover {
      background-color: #000F08;
      border-color: #000F08;
      color: #69DDFF; }
    #contact_form button:focus {
      outline: none !important; }
  #contact_form #loading {
    display: none;
    text-align: center; }
    #contact_form #loading .spinner-border {
      margin: 40px auto 0;
      width: 50px;
      height: 50px;
      color: #69DDFF !important; }
  #contact_form #results {
    text-align: center;
    margin: 15px auto;
    font-size: 1.4rem;
    display: none; }

#footer-main {
  background-color: #000F08;
  color: #FBFEF9;
  min-height: 60px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  #footer-main p {
    margin: 0;
    font-size: 1.2rem; }
  #footer-main #footer_social_icons {
    font-size: 1.2rem; }
    #footer-main #footer_social_icons a {
      color: #FBFEF9; }
      #footer-main #footer_social_icons a:hover, #footer-main #footer_social_icons a:active, #footer-main #footer_social_icons a:focus {
        color: #69DDFF; }
    #footer-main #footer_social_icons svg {
      width: 30px;
      height: 30px;
      margin: 5px; }

@media (max-width: 600px) {
  #footer-main {
    flex-direction: column;
    justify-content: center; }
    #footer-main p {
      font-size: 1rem; }
    #footer-main #footer_social_icons {
      margin: 10px; } }

.custom-modal {
  display: none;
  position: fixed;
  z-index: 90;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }
  .custom-modal .modal-content {
    position: relative;
    background-color: #FBFEF9;
    border: none !important;
    margin: auto;
    padding: 0;
    width: 80%;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    z-index: 99;
    border-radius: 0 !important; }

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }
  .custom-modal .close {
    color: #69DDFF !important;
    font-size: 28px;
    font-weight: bold; }
    .custom-modal .close:hover, .custom-modal .close:focus {
      color: #69DDFF;
      text-decoration: none;
      cursor: pointer; }
  .custom-modal .modal-header {
    padding: 6px 16px;
    background-color: #2D3142;
    color: #FBFEF9;
    border-bottom: 5px solid #69DDFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 !important; }
    .custom-modal .modal-header h2 {
      color: #FBFEF9 !important;
      margin: 0;
      padding: 0;
      font-size: 1.6rem  !important; }
  .custom-modal .modal-body {
    padding: 16px; }
    .custom-modal .modal-body img {
      width: 100%;
      max-width: 100%;
      height: auto; }
  .custom-modal .modal-footer {
    padding: 6px 16px;
    background-color: #FBFEF9;
    border-top: 2px solid #e8e8e8;
    color: #000F08;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 !important; }
    .custom-modal .modal-footer p {
      margin: 0;
      padding: 0; }
    .custom-modal .modal-footer button {
      margin: 5px;
      background-color: #69DDFF;
      border-color: #69DDFF;
      color: #FBFEF9; }
      .custom-modal .modal-footer button:hover {
        background-color: #000F08;
        border-color: #000F08;
        color: #FBFEF9; }

#social_icons {
  position: absolute;
  color: #FBFEF9;
  display: flex;
  flex-direction: column;
  right: 15px;
  bottom: 45%;
  z-index: 190; }
  #social_icons a {
    color: #FBFEF9; }
    #social_icons a:hover, #social_icons a:active, #social_icons a:focus {
      color: #69DDFF; }
  #social_icons svg {
    width: 30px;
    height: 30px;
    margin: 5px; }

@media (max-width: 600px) {
  #social_icons {
    bottom: 5%; } }

