#home{
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background-color: $dark;
    color:$light;

    .sticky-nav{
        border-bottom: solid 2px $medium-light;
        background-color: $dark;
    }

    //background
    .bg-image{
        position: absolute;
        width: 100%;
        height: 100vh;
        background: $dark url('/img/home-header.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        z-index: 0;
        opacity: 0.4;
    }

    .nav-height{
        display: none;
        height:80px;
    }

    //intro
    #intro{
        z-index: 1; 
        @extend .main-padding;
        text-align: left;

        .greeting{
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        h1{
            font-size: 4rem;
            margin: 15px 0;
        }

        p{
            font-size: 1.2rem;
            line-height: 1.8rem;
            max-width: 80%;
        }
    }

    //footer
    #footer-intro{
        padding: 30px 0;
        z-index: 1;
        position: relative;
    }

    .icon-scroll,
    .icon-scroll:before {
        position: absolute;
        left: 50%;
    }
    .icon-scroll {
        width: 40px;
        height: 70px;
        margin-left: -20px;
        top: 0;
        margin-top: -35px;
        box-shadow: inset 0 0 0 1px #fff;
        border-radius: 25px;
    }
    .icon-scroll:before {
    content: '';
        width: 8px;
        height: 8px;
        background: #fff;
        margin-left: -4px;
        top: 8px;
        border-radius: 4px;
        -webkit-animation-duration: 1.5s;
                animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-name: scroll;
                animation-name: scroll;
    }
    @-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(46px);
                transform: translateY(46px);
    }
    }
    @keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(46px);
                transform: translateY(46px);
    }
    }

}

//nav
#nav-wrapper{
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 300;

    @extend .main-padding;

    .menu-icon{
        display: none;
    }

    #logo{

        img{
            max-width: 50px;
        }

        svg{
            width: 36px;
            height: 36px;
            color: $light;
            fill: $light;
        }
        
    }

    nav{
        margin: 0 0 0 auto;
    }



    ul{
        list-style: none;
        margin: 0;

        li{
            display: inline-block;

            a{
                color: $light;
                text-decoration: none;
                font-size: 1.2rem;
                transition: 0.3s;
                padding: 0 10px;
    
                &:hover{
                    color:$medium-light;
                }
                
            }

            .selected{
                color:$medium-light;
            }
        }


    }
}

@media(max-width:600px){

    
    #intro{
        .greeting{
            font-size: 1.2rem !important;
            line-height: 2.0rem !important;
        }

        h1{
            font-size: 2.6rem !important;
            margin: 15px 0;
        }

        p{
            font-size: 1.0rem !important;
            line-height: 1.6rem !important;
            max-width: 100%;
        }
    }

    #nav-wrapper{

        .menu-icon{
            display: block;
            cursor: pointer;

            .bar1, .bar2, .bar3 {
                width: 35px;
                height: 5px;
                background-color: $light;
                margin: 6px 0;
                transition: 0.4s;
              }
              
        }

        .change .bar1 {
            -webkit-transform: rotate(-45deg) translate(-9px, 6px);
            transform: rotate(-45deg) translate(-9px, 6px);
        }
            
        .change .bar2 {opacity: 0;}
        
        .change .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
        }

        .show-always{
            display: block !important;
        }

        #logo{
            float:left;
        }

        .menu-icon{

            background-color: transparent !important;
            border:none;

            &:focus, &:hover{
                background-color: transparent !important;
                border:none;
                outline:0;
            }

            svg{
                height: 30px;
                width: 30px;
                color:$light;
            }
            
            float: right;
            cursor: pointer;
        }

        position: fixed;
        display: block;

        nav{
            clear:both;
            margin: 30px 0 0 0;
            width: 100%;
        }

        ul{
            width: 100%;
            background-color: $medium-dark;
            display: none;

            li{
                display: block;
                padding:8px;
            }
        }
    }

}

#back2Top {
    width: 40px;
    line-height: 40px;
    overflow: hidden;
    z-index: 999;
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 80px;
    right: 10px;
    background-color: $medium-dark;
    color: $medium-light;
    text-align: center;
    font-size: 30px;
    text-decoration: none;
}

#back2Top:hover {
    background-color: $medium-light;
    color: $medium-dark;
}