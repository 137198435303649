.custom-modal {
    display: none; 
    position: fixed; 
    z-index: 90; 
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 

    .modal-content {
        position: relative;
        background-color: $light;
        border:none !important;
        margin: auto;
        padding: 0;
        width: 80%;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        z-index: 99; 
        border-radius: 0!important;
    }

    @-webkit-keyframes animatetop {
        from {top:-300px; opacity:0} 
        to {top:0; opacity:1}
        }
      
    @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
    }

    .close {
        color: $medium-light !important;
        font-size: 28px;
        font-weight: bold;

        &:hover, &:focus{
            color: $medium-light;
            text-decoration: none;
            cursor: pointer;
        }
    }
      

    
    .modal-header {
        padding: 6px 16px;
        background-color: $medium-dark;
        color: $light;
        border-bottom: 5px solid $medium-light;
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0!important;

        h2{
            color: $light !important;
            margin:0;
            padding:0;
            font-size:1.6rem  !important;
        }
    }
      
    .modal-body {
        padding: 16px;

        img{
            width:100%;
            max-width: 100%;
            height:auto;
        }
    }
      
    .modal-footer {
        padding: 6px 16px;
        background-color: $light;
        border-top:2px solid $neutral;
        color: $dark;
        display:flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0!important;

        p{
            margin: 0;
            padding: 0;
        }

        button{
            margin: 5px;
            background-color:$medium-light;
            border-color:$medium-light;
            color:$light;
            &:hover{
                background-color:$dark;
                border-color:$dark;
                color:$light;
            }
        }
    }
}